import * as Types from '@aeppic/types'

import Model, { DocumentImportResult } from './model'
import { isForm } from './is'

import LookupList from './lookup-list'
import { buildLogger } from './log'

const log = buildLogger('importer')

export const IMPORT_OPERATION_UPDATE = 'u'
export const IMPORT_OPERATION_DELETED = 'deleted'
export const IMPORT_OPERATION_DELETED_HARD = 'deleted-hard'
export const IMPORT_OPERATION_ADDED_DETACHED_VERSION = 'added-detached-version'

export interface ImportEventOperation {
  type: typeof IMPORT_OPERATION_UPDATE | typeof IMPORT_OPERATION_DELETED | typeof IMPORT_OPERATION_DELETED_HARD
  document: Types.Document
}

export type ImportOperation = Types.Document | ImportEventOperation 
export type LegacyImportOperation = any | ImportOperation 

export interface ImportResult {
  added: number
  deleted: number
  failed: ImportOperation[]
  cycles: number
  ignored: number
}

/**  */
export interface DocumentLookupCallback {
  /** 
   * @argument requiredDocumentInfos <string[]>   2-Tuples of id and version (or null) of required documents
   *                                              e.g ['a', null, 'b', 'v2'] to find document 'a' (any version) and 
   *                                              document 'b' with version 'v2'. Versions are only required from
   *                                              forms.
   * 
   * @returns Promise of array of documents found. The document list may include more documents
   *          than originally requested, for example entire ancestor chains,
   *          but the initial document requested must be in it or an import will fail.
   */
  (requiredDocumentInfos: string[]): Promise<Types.Document[]>
}

interface DocumentImportSkipList {
  documents: Set<Types.Document>
  lookups: LookupList
}

/**
 * Import all documents using `model.import` while correcting the order
 * and calling the lookup function for any missing documents.
 * 
 * Only use the importer when order or completeness can not be guaranteed.
 * Calling `model.import` directly is always faster and synchronous.
 * 
 * The lookup function might be called multiple times since the returned
 * documents might have missing documents themselves
 * 
 * All documents need to be resolvable via the lookup function
 * 
 * TODO: Ignore documents that are not fully resolvable
 * 
 * @param documents 
 * @param lookupCallback 
 * 
 * @returns A result of the import
 */
export async function importIntoModel(model: Model, operations: (ImportOperation|LegacyImportOperation)[], options: { addIntoFormsDirectoryOnly?: boolean, documentLookupCallback?: DocumentLookupCallback, useLegacyLoadingProtocol?: boolean  }): Promise < ImportResult > {
  const importer = new Importer(model, options)
  return importer.import(operations)    
}

export class Importer {
  private _result: ImportResult = {
    added: 0,
    deleted: 0,
    failed: [],
    cycles: 0,
    ignored: 0
  }

  constructor(private _model: Model, private _options: { addIntoFormsDirectoryOnly?: boolean, documentLookupCallback?: DocumentLookupCallback, useLegacyLoadingProtocol?: boolean }) {
  }

  /**
   * Call this to import a batch of documents into the associated model. Can only be called
   * once per instance.
   * 
   * @param documents 
   * @returns A promise with an ImportResult 
   * 
   */
  async import(operations: ImportOperation[]): Promise<ImportResult> {
    let currentBatch = operations
    
    let MAX_ATTEMPTS = 25
    let attempt = 1

    for (; attempt <= MAX_ATTEMPTS; attempt += 1) {
      const skipList: DocumentImportSkipList = {
        documents: new Set(),
        lookups: new LookupList()
      }

      for (let i = 0; i < currentBatch.length; i += 1) {
        const nextOperation = currentBatch[i]

        if (nextOperation) {
          this._import(nextOperation, currentBatch, skipList)
        }

        currentBatch[i] = null
      }

      if (skipList.documents.size === 0) {
        break
      }      

      currentBatch = Array.from(skipList.documents.values())

      if (skipList.lookups.entries.length > 0 && !(this._options && this._options.documentLookupCallback)) {
        console.error('could not lookup (no lookup callback defined)', skipList.lookups.entries)
        this._result.failed = currentBatch
      } else {
        
        const result = await this._options.documentLookupCallback(skipList.lookups.entries)

        if (result && result.length > 0) {
          const lookupImportResult = await this.import(result)
          this._result.cycles += lookupImportResult.cycles + 1
        } else {
          this._result.failed = currentBatch
          break
        }
      }
    }

    if (attempt > MAX_ATTEMPTS) {
      this._result.failed = currentBatch
    }

    return this._result
  }

  private _import(importOperation: ImportOperation, otherOps: ImportOperation[], skipList: DocumentImportSkipList): boolean {
    let documentToImport: Types.Document
    let addIntoFormsDirectoryOnly = this._options && this._options.addIntoFormsDirectoryOnly
    const useLegacyLoadingProtocol = this._options && this._options.useLegacyLoadingProtocol 

    if (isImportEventOperation(importOperation)) {
      const type = importOperation.type || (<any>importOperation).operation

      if (type === IMPORT_OPERATION_UPDATE) {
      } else if (type === IMPORT_OPERATION_DELETED_HARD) {
        this._model.deleteHard(importOperation.document.id)
        this._result.deleted += 1
        return true
      } else if (type === IMPORT_OPERATION_DELETED) {
        const justId = !importOperation.document.data

        if (justId) {
          this._model.deleteHard(importOperation.document.id)
        } else {
          // This will be a move to the recycler
          this._model.import(importOperation.document)
        }
        this._result.deleted += 1
        return true
      } else if (type === IMPORT_OPERATION_ADDED_DETACHED_VERSION) {
        addIntoFormsDirectoryOnly = true
      } 

      documentToImport = importOperation.document

      if (!documentToImport) {
        console.error('No document in import operation', importOperation)
        return
      }
    } else {
      documentToImport = importOperation
    }

    const legacyDocument = <any>documentToImport

    if (!legacyDocument) {
      return
    }

    if ('previous' in legacyDocument && typeof legacyDocument.previous === 'string') {
      const previous = {
        v: legacyDocument.previous,
        ...legacyDocument.previousDocument
      }
      legacyDocument.previous = previous
      delete legacyDocument.previousDocument
    }

    if (!documentToImport.p && documentToImport.id !== 'root') {
      log.error(`Document '${documentToImport.id}' is missing parent`, importOperation)
      // log.error(documentToImport)
      return false
    }

    if (documentToImport.p === documentToImport.id) {
      log.error(`Document '${documentToImport.id}' is referencing itself as parent`)
      return false
    }

    let isMissingSomething = false

    // 
    // if (!this._model.isDocumentFormKnown(documentToImport)) {
    //   const documentForm = lookAheadForDocument(otherOps, documentToImport.f.id, documentToImport.f.v)

    //   if (!documentForm) {
    //     skipList.documents.add(documentToImport)
    //     skipList.lookups.push(documentToImport.f.id, documentToImport.f.v)
    //     isMissingSomething = true
    //   } else {
    //     const imported = this._import(documentForm, otherOps, skipList)

    //     if (!imported) {
    //       isMissingSomething = true
    //     }
    //   }
    // }

    const parentIsRequired = useLegacyLoadingProtocol ? !isForm(documentToImport) : !isForm(documentToImport) || !addIntoFormsDirectoryOnly

    if (parentIsRequired) {
      if (!this._model.isDocumentParentKnown(documentToImport, { includeRecycler: true })) {
        const parentId = documentToImport.p
        const parentDocument = lookAheadForDocument(otherOps, parentId) 

        if (!parentDocument) {
          skipList.documents.add(documentToImport)
          skipList.lookups.push(parentId, null)
          isMissingSomething = true
        } else {
          const imported = this._import(parentDocument, otherOps, skipList)

          if (!imported) {
            isMissingSomething = true

            skipList.documents.add(documentToImport)
            skipList.lookups.push(documentToImport.id, null)
          }
        }
      }
    }

    if (isMissingSomething) {
      return false
    }

    const importOptions = { 
      addIntoFormsDirectoryOnly: addIntoFormsDirectoryOnly || (this._options && this._options.addIntoFormsDirectoryOnly),
      useLegacyLoadingProtocol: this._options && this._options.useLegacyLoadingProtocol
    }

    if (documentToImport) {
      const result = this._model.import(documentToImport, importOptions)

      if (result & DocumentImportResult.Imported) {
        this._result.added += 1
      } else if (result & (DocumentImportResult.Duplicate || DocumentImportResult.Outdated || DocumentImportResult.InvalidSchema)) {
        this._result.ignored += 1
      }
    } else {
      this._result.ignored += 1
    }

    return true   
  }
}


/**
 * Find the defined document, specific to the version and set its location in the
 * array to null
 * 
 * @param documents 
 * @param id 
 * @param version 
 * @returns 
 */
function lookAheadForDocument(ops: ImportOperation[], id: string, version?: string) {
  for (let i = 0; i < ops.length; i += 1) {
    const op = ops[i]

    if (op == null) {
      continue
    }

    let document: Types.Document

    if (isImportEventOperation(op)) {
      if (op.type === IMPORT_OPERATION_DELETED_HARD) {
        continue
      }
      document = op.document
    } else {
      document = op
    }

    if (document.id === id) {
      if (version != null && document.v !== version) {
        continue
      }

      // console.log('lookedAhead and found', document.id)
      ops[i] = null
      return document
    }
  }
}

function isImportEventOperation(operation: ImportOperation): operation is ImportEventOperation {
  return ('type' in operation || 'operation' in operation)
}
