import * as Types from '@aeppic/types'

import uuid from  '@aeppic/shared/uuid'
// import { buildDeferred, Deferred } from  '@aeppic/shared/defer'

import { lookupDefaultControlName } from '../utils/control-names'
import DynamicComponent from '../dynamic/dynamic-component'
import createEventForwarders from '../utils/create-event-forwarders'
import { Form, FormFieldAtPlaceholder } from '../../model/form.js'
import Aeppic from 'aeppic/index.js'
import { ControlLookup, ControlMatchStrategy } from '../lookup/control-lookup.js'
import { isDocument } from '../../model/is.js'
import { ParsedField } from '@aeppic/forms-parser'
import { DocumentLookupCache } from './cache.js'
import { OnceCache } from '@aeppic/shared/once'

const DEFAULT_NAMESPACE = 'default'

export default {
  render() {
    const _vm = this
    const _h = _vm.$createElement
    const _c = _vm._self._c || _h

    if (_vm.canRender) {
      return _vm._c(_vm.dynamicComponent.id, {
        key: _vm.dynamicComponent.document.id,
        tag: _vm.component,
        props: {
          control: _vm.dynamicComponent.document,
          params: _vm.paramsToBindTo,
          document: _vm.documentToBindTo,
          form: _vm.formToBindTo,
          fieldInfo: _vm.parsedFieldInfo,
          fieldName: _vm.fieldNameToBindTo,
          label: _vm.label,
          readonly: _vm.readonly,
          uniqueId: _vm.uniqueId,
          validate: _vm.validate,
        },
        on: createEventForwarders(_vm._events, _vm)
      })  
    }

    return _vm._e()
  },
  renderError(h, err) {
    return h('pre', { staticClass: 'ae-error', style: { color: 'red' }}, 'ae-control:' + this.name + ' ' +  err.stack)
  },
  inject: ['getAeppicContext'],
  props: {
    name: String,
    params: Object,
    field: Object, // ParsedField
    fieldName: String,
    document: Object,
    form: Object,
    readonly: Boolean,
    validate: {
      type: Boolean,
      default: true
    },
    label: String,
    watch: {
      type: Boolean,
      default: true
    },
    watchControl: {
      type: Boolean,
      default: null
    },
    watchControlRevisions: {
      type: Boolean,
      default: null
    },
    disableDefaultControl: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Object,
      default: null
    },
  },
  data() {
    const Aeppic = this.getAeppicContext('ae-control-selector', this.document)

    const isDeveloper = Aeppic.Account && Aeppic.Account.data.allowDevMode

    const watch = typeof this.watchControl === 'boolean' ? this.watchControl : isDeveloper
    const watchRevisions =  Aeppic.Developer.isServerInRemoteDeveloperMode ? false
                          : typeof this.watchControlRevisions === 'boolean' ? this.watchControlRevisions
                          : isDeveloper

    const dynamicComponent = new DynamicComponent('control', Aeppic, {
      formId: 'control-form',
      template: CONTROL_COMPONENT_TEMPLATE,
      exposedSymbols: '$el, $refs, $listeners, Aeppic, Math, DateTime, Anime, locals, control, document, getField, setField, fieldName, fieldInfo, form, params, readonly, watchParam, $on, $emit, translate, validationErrors, validate',
      watch,
      watchRevisions
    })

    return {
      Aeppic,
      uniqueId: uuid(),
      dynamicComponent,
      documentToBindTo: null,
      error: null,
      formToBindTo: null,
      fieldInfo: null,
      fieldNameToBindTo: null,
      paramsToBindTo: {}
    }
  }, 
  beforeDestroy() {
    this.dynamicComponent.destroy()
    this.Aeppic.release()
  },
  computed: {
    canRender() {
      return this.dynamicComponent != null && this.dynamicComponent.id && this.documentToBindTo && this.formToBindTo && this.checkFieldValidity()
    }
  },
  methods: {
    checkFieldValidity() {
      if (!this.formToBindTo) {
        return false
      }

      if (this.fieldNameToBindTo && !this.formToBindTo.info.fields.hasOwnProperty(this.fieldNameToBindTo)) {
        return false
      }

      return true
    },
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  created() {
    this.loadControl = async function() {
      this.documentToBindTo = await this.document
      this.formToBindTo = await this.loadForm(this.documentToBindTo)
      
      if (!this.documentToBindTo) {
        return
      }
      
      if (!this.formToBindTo) {
        return
      }

      const placeholderParams = this.placeholder ? this.formToBindTo.getPlaceholderControlParams(this.placeholder.index) : {}
      this.paramsToBindTo = { ...placeholderParams, ...this.params }

      const fieldName = this.field?.name ?? this.fieldName
      this.fieldNameToBindTo = fieldName

      if (fieldName && !this.checkFieldValidity()) {
        this.error = `Unknown field '${fieldName}'. Does not exist in form '${this.formToBindTo.id}@${this.formToBindTo.v}'`
        return
      }

      if (fieldName) {
        this.parsedFieldInfo = this.formToBindTo.getField(fieldName)
      } else {
        this.parsedFieldInfo = null
      }

      this.error = null

      // We do not pass on the placeholder offset for temporary forms
      // as the placeholder offset would be used by the findControl function
      // to look up the control from the server which does not know this form version
      const placeholderOffset = this.formToBindTo.isTemporary ? null : this.placeholder?.offset
      const controlDocument = await findControl(this.Aeppic, this.formToBindTo, this.parsedFieldInfo, this.name, placeholderOffset, this.disableDefaultControl)

      if (controlDocument) {
        this.dynamicComponent.refresh(controlDocument)
      }
      else if (fieldName) {
        this.error = `Could not find matching control {${this.name || ''}} for field [${fieldName}]`
        return null
      }
      else {
        this.error = `Could not find matching standalone control {${this.name || ''}}`
        return null
      }
    }

    this.loadForm = async function(d: Types.Document) {
      return this.form || this.Aeppic.getFormForDocument(d)
    }

    this.loadControlWithoutCache = function() {
      clearCacheForForm(this.formToBindTo)
      this.loadControl()
    }

    this.dynamicComponent.on('invalidated', () => this.loadControlWithoutCache())

    this.loadControl()
  },
  watch: {
    name() {
      this.loadControl()
    },
    document() {
      this.loadControl()
    },
    fieldName() {
      this.loadControl()
    },
    // document(newDocument, previous) {
    //   if (newDocument == null || previous == null) {
    //     this.configureControl()
    //   } 
    // },
    form() {
      this.loadControl()
    },
    params() {
      this.loadControl()
    },
  }
}

interface FormsControlCache {
  addedAt: number
  lru: number
  controls: Map<string, Types.Document>
}

const CONTROL_LOOKUP_CACHING = true
const cache = new Map<string, FormsControlCache>()

function clearCacheForForm(form) {
  if (form) {
    cache.delete(form.id)
  }
}

const STRATEGY_NAME_TO_ENUM = {
  'default-only': ControlMatchStrategy.DEFAULT_ONLY,
  'full': ControlMatchStrategy.FULL,
  'system': ControlMatchStrategy.SYSTEM,
  'classic': null
}

type PlaceholderControlLookupMap = {
  [key: number]: string
}

const controlLookupMapCache = new OnceCache<PlaceholderControlLookupMap, { aeppic: Aeppic, form: Types.Reference }>(`control-lookup-map`, async ({ aeppic, form }: { aeppic: Aeppic, form: Types.Reference } ) => {
  const controlLookupInfo = await aeppic.fetch(`/api/forms/${form.id}/controls?v=${form.v}`)

  if (controlLookupInfo.ok) {
    const result = await controlLookupInfo.json()
    return result.controls
  } else {
    throw new Error(`Failed to fetch control lookup map for form ${form.id}@${form.v}`)
  }
})

async function findControl(aeppic: Aeppic, form: Types.Reference, field: ParsedField, controlName: string, placeholderOffset: number, disableDefaultControl: boolean): Promise<Types.Document> {
  const fieldName = field?.name

  if (!aeppic.Features.isEnabled('control-lookup')) {
    return classicFindControl(aeppic, form.id, fieldName, controlName, disableDefaultControl)
  }

  const { strategy: strategyOption, fallback } = aeppic.Features.getOptionsFor('control-lookup') ?? { strategy: 'full' }
  const strategy: ControlMatchStrategy = STRATEGY_NAME_TO_ENUM[strategyOption]

  let controlDocument: Types.Document
  const cachedControlLookupForForm =  placeholderOffset == null  ? null : await controlLookupMapCache.get(`${form.id}@${form.v}`, { aeppic, form })

  if (cachedControlLookupForForm) {
    const controlId = cachedControlLookupForForm[placeholderOffset]
    
    if (controlId === null) {
      return null
    } else if (controlId) {
      const controlDocument = await aeppic.get(controlId)

      if (!fallback) {
        return controlDocument
      }

      const nameSpaceFound = controlDocument?.data.namespace as string ?? ''
      const nameFound = controlDocument?.data.name as string ?? '<undefined>'
      
      const fullNameFound = `${nameSpaceFound}:${nameFound}`
      const fullControlName = controlName.includes(':') ? controlName : `:${controlName}`

      if (fullNameFound.trim().toLocaleLowerCase() === fullControlName.trim().toLocaleLowerCase()) {
        return controlDocument
      } else {
        aeppic.Warn.once("control-lookup:form-control-map:mismatch", form.id + form.v, `Mismatched control for placeholder ${placeholderOffset} in form ${form.id}@${form.v}. Expected ${fullControlName} got ${fullNameFound}. Fallback to classic lookup`)
        return classicFindControl(aeppic, form.id, fieldName, controlName, disableDefaultControl)
      }
    } else {
      aeppic.Warn.once("control-lookup:form-control-map", form.id + form.v, `Could not find control for placeholder ${placeholderOffset} in form ${form.id}@${form.v}.`)
    }
  } else {
    controlDocument = await enhancedFindControl(aeppic, form.id, field, controlName, strategy)
  }

  if (strategy != null) {
    if (controlDocument) {
      return controlDocument
    } else if (fallback) {  
      if (fieldName) {
        const key = `${form.id}:${fieldName}:${controlName}`
        aeppic.Warn.once('control-lookup', key, `Could not find control ${controlName} for field ${fieldName} in form ${form.id}@${form.v}. Falling back to classic lookup.`)
      } else {
        const key = `${form.id}:${controlName}`
        aeppic.Warn.once('control-lookup', key, `Could not find standalone control ${controlName} in form ${form.id}@${form.v}. Falling back to classic lookup.`)
      }
      return await classicFindControl(aeppic, form.id, fieldName, controlName, disableDefaultControl)
    }

    return null
  }

  const classicControl = await classicFindControl(aeppic, form.id, fieldName, controlName, disableDefaultControl)

  if (controlDocument?.id !== classicControl?.id) {
    if (fieldName) {
      const key = `${form.id}:${fieldName}:${controlName}`
      aeppic.Warn.once('control-lookup', key, { newControl: controlDocument, classicControl }, `Control lookup mismatch for control ${controlName} for field ${fieldName} in form ${form.id}.`)
    } else {
      const key = `${form.id}:${controlName}`
      aeppic.Warn.once('control-lookup', key, { newControl: controlDocument, classicControl }, `Control lookup mismatch for control ${controlName} in form ${form.id}.`)
    }
  }

  return classicControl
}

class ControlLookupCache {
  private _cache: DocumentLookupCache

  constructor(aeppic: Aeppic) {
    this._cache = new DocumentLookupCache(aeppic, 'control-form')
  }

  get(formId: Types.DocumentId, field: ParsedField, controlName: string, strategy: ControlMatchStrategy): Promise<Types.Document|null>|Types.Document|undefined {
    const key = buildControlCacheKey(formId, field, controlName, strategy)
    return this._cache.get(key)
  }

  set(formId: Types.DocumentId, field: ParsedField, controlName: string, strategy: ControlMatchStrategy, controlDocument: Types.Document) {
    const key = buildControlCacheKey(formId, field, controlName, strategy)
    return this._cache.set(key, controlDocument)
  }
}

function buildControlCacheKey(formId: Types.DocumentId, field: ParsedField, controlName: string, strategy: ControlMatchStrategy) {
  if (field) {
    return `${formId}:${field.name}:${controlName}:${strategy}`
  } else {
    return `${formId}:${controlName}:${strategy}`
  }
}

let CONTROL_LOOKUP_CACHE = null
let controlLookup = null

// Enhanced control lookup
//
// - controlSelector: string // The namespace and name of the control (as defined in the styling directive. e.g 'ae:my-select')
async function enhancedFindControl(aeppic: Aeppic, formId: Types.DocumentId, field: ParsedField, controlName: string, strategy: ControlMatchStrategy): Promise<Types.Document> {
  if (!CONTROL_LOOKUP_CACHE) {
    CONTROL_LOOKUP_CACHE = new ControlLookupCache(aeppic)
  }

  const options = aeppic.Features.getOptionsFor('control-lookup') ?? {}

  if (options.cache) {
    const cached = await CONTROL_LOOKUP_CACHE.get(formId, field, controlName, strategy)

    if (cached || cached === null) {
      return cached
    }
  }

  if (!controlLookup) {
    controlLookup = new ControlLookup(aeppic, strategy)
  }

  const control = await controlLookup.findControlDocument(formId, field, controlName)

  if (options.cache) {
    CONTROL_LOOKUP_CACHE.set(formId, field, controlName, strategy, control)
  }

  return control
}

async function classicFindControl(aeppic: Aeppic, formId: Types.DocumentId, fieldName: string, controlName: string, disableDefaultControl): Promise<Types.Document> {
  if (!CONTROL_LOOKUP_CACHING) {
    const { controlDocument } = await _findControl(aeppic, formId, fieldName, controlName, disableDefaultControl)
    return controlDocument
  }

  let controlEntries = cache.get(formId)

  if (!controlEntries) {
    controlEntries = { addedAt: Date.now(), lru: Date.now(), controls: new Map() }
    cache.set(formId, controlEntries)
  } else {
    controlEntries.lru = Date.now()
  }

  if (controlEntries.controls.has(controlName)) {
    return controlEntries.controls.get(controlName)
  }

  // const { controlDocument, controlName: providedOrDefaultControlName} = await _findControl(aeppic, form, fieldName, controlName, disableDefaultControl)
  const info = await _findControl(aeppic, formId, fieldName, controlName, disableDefaultControl)
  const controlDocument = info && info.controlDocument
  const providedOrDefaultControlName = info && info.controlName
  controlEntries.controls.set(providedOrDefaultControlName, controlDocument)
  return controlDocument
}

/** 
 * 
 * Lookup rule:
 *
 * Check each of the following folder locations for a control matching
 * that name and namespace. If nothing found also check for namespace 'default' before
 * moving on to next location.
 *
 * Get form-folder -> find control locations defined from there (TODO)
 * Get application the form is located in -> find control locations defined from there (TODO)
 * Look in /System/Controls (controls-folder) folder 
 * Look in /System/Controls/Fallback Controls 
 *
 * Look for any control with matching name (namespace:name) anywhere 
 *
 */

async function _findControl(aeppic, formId, fieldName, controlName, disableDefaultControl) {
  const form = await aeppic.getForm(formId)
  const controlDocument = await findControlByName(aeppic, form, controlName)

  if (controlDocument) {
    return {
      controlDocument,
      controlName
    }
  } else if (fieldName) {
    const field = form.info.fields[fieldName]

    if (!field) {
      return {
        controlDocument,
        controlName
      }
    }

    if (disableDefaultControl) {
      return { 
        controlDocument: null, 
        controlName
      }
    }

    const defaultTypeControlName = lookupDefaultControlName(field)
    const typedBasedControl = await findControlByName(aeppic, form, defaultTypeControlName)

    if (typedBasedControl) {
      return {
        controlDocument: typedBasedControl,
        controlName: defaultTypeControlName
      }
    } else {
      return {
        controlDocument: aeppic.get('default-text-control'),
        controlName: 'default-text-control'
      }
    }
  } 
}

async function findControlByName(aeppic, form, controlName) {
  if (!controlName) {
    return null
  }

  let name = controlName
  let namespace = ''

  if (controlName.indexOf(':')) {
    const parts = controlName.split(':', 2)

    if (parts.length === 1) {
      [name] = parts
    } else {
      [namespace, name] = parts
    }
  }

  name = name.trim().toLowerCase()
  namespace = namespace.trim().toLowerCase()

  // const locations = [
  //   form.id,
  //   // form.p, // NOT YET EXPOSED .. but should look into form-folder/controls multi-refs
  //   // also the form.p cannot be used anyway. it should be the current p of the most recent form
  //   // which might have moved since the original date
  //   '2187d93e-90b9-46cd-9956-c665a08f0aff',
  //   'controls-folder',
  //   '91f205bc-9989-4fbe-8966-bd4ba9777c26'
  // ] 

  const locations = await getControlLocations(aeppic, form)

  for (const location of locations) {
    const matchingControl = await lookupControlInLocation(aeppic, location, namespace, name)
    if (matchingControl) {
      return matchingControl
    }
  }
}

async function getControlLocations(aeppic, form) {
  // const ancestors = await aeppic.getAll(form.document.a)
  const locations = []

  // for (let ancestor of ancestors) {
  //   if ((ancestor.f.id === 'application-form') || (ancestor.p === 'apps')) {
  //     let controlFolders = await aeppic.find(`p:"${ancestor.id}" AND data.name:controls`)

  //     for (let controlFolder of controlFolders) {
  //       locations.unshift(controlFolder.id)
  //     }
  //   }
  // }
  
  locations.push('controls-folder')
  locations.push('91f205bc-9989-4fbe-8966-bd4ba9777c26') // default controls

  return locations
}

async function lookupControlInLocation(aeppic, locationDocumentId, namespace, name) {
  const controls = await aeppic.find(`p:${locationDocumentId} f.id:control-form`, { size: 1000 })

  for (const control of controls) {
    if (isMatchingControl(control, namespace, name)) {
      return control
    }
  }

  for (const control of controls) {
    if (isMatchingControl(control, DEFAULT_NAMESPACE, name)) {
      return control
    }
  }

  return null
}

function isMatchingControl(controlDocument, namespace, name) {
  if (controlDocument.data.packages == null) {
    return false
  }

  return (controlDocument.data.name.trim().toLowerCase() === name &&
          controlDocument.data.namespace.trim().toLowerCase() === namespace) 
}

const CONTROL_COMPONENT_TEMPLATE = `{
  template: __TEMPLATE__,
  inject: ['getAeppicContext'],
  props: ['params', 'control', 'document', 'fieldName', 'fieldInfo', 'form', 'label', 'readonly', 'uniqueId', 'validate'],
  beforeCreate() {
    this.nextValue = null
    this.timerNextValue = null

    this.processNextValue = (value) => {
      this.Aeppic.clearTimeout(this.timerNextValue)
      this.timerNextValue = null

      // delay again if value has changed
      if (value !== this.nextValue) {
        this.setFieldValueAfterDelay(this.nextValue)
        return
      }

      this.setFieldValue(this.nextValue)
      this.nextValue = null
    }

    this.setFieldValueAfterDelay = (value, delay = 500) => {
      this.nextValue = value

      if (this.timerNextValue) {
        return
      }

      // delay processing enough to allow for multiple changes to be batched
      // setTimeout is used to yield as quickly as possible back to the event loop (= UI thread)
      this.timerNextValue = this.Aeppic.setTimeout(() => {
        this.processNextValue(value)
      }, delay)
    }

    function getType(value) {
      return Object.prototype.toString.call(value).slice(8, -1).toLowerCase()
    }

    this.getFieldValue = () => {
      if (this.isStandalone) {
        return null 
      } else {
        const value = this.document.data[this.fieldName]

        if (value === undefined) {
          console.error('Field is not defined', this.fieldName, this.document)
        }

        // make shallow copy of the array to avoid mutation of original field array before calling setField
        if (Array.isArray(value)) {
          return value.slice()
        }

        return value
      }
    }

    this.setFieldValue = (newValue) => {
      if (this.timerNextValue) {
        this.Aeppic.clearTimeout(this.timerNextValue)
        this.timerNextValue = null
        this.nextValue = null
      }

      if (this.readonly) {
        return
      }

      if (this.document.data[this.fieldName] === newValue) {
        return
      }

    
      const expectedType = this.fieldInfo.type
      const error = {field:'', type: '', expectedType: expectedType}
      if (this.fieldInfo.cardinality) {
        if (getType(newValue) !== 'array') {
          error.type = getType(newValue)
          error.field = 'value',
          error.expectedType = 'array'
        } else {
          for (let i = 0; i < newValue.length; i += 1) {
            const type = getType(newValue[i])
            
            if (type === expectedType || type === getType(this.fieldInfo.defaultValue)) {
              continue
            }

            error.field =  \`value[index: \${i}]\`
            error.type = type

            break
          }
        }
      } else {
        const type = getType(newValue)
        if (type !== expectedType && type !== getType(this.fieldInfo.defaultValue)) {
          error.field = 'value'
          error.type = type
        }
      }

      if (error.field) {
        console.error(\`Could not set field: Expected "\${error.field}" to be of type "\${error.expectedType}" but got "\${error.type}"\`)
        return
      }

      this.document.data[this.fieldName] = newValue

      this.$emit('field-updated', { fieldName: this.fieldName, value: newValue })
    }

    this.getValidationErrors = () => {
      // check Aeppic to see if component is not yet fully initialized
      if (!this.validate || !this.Aeppic) {
        return null
      }

      if (this.Aeppic.isEditableDocument(this.document)) {
        return this.document.getValidationErrorsForField(this.fieldName)
      }

      return this.form.validateDocumentDataField(this.document.data, this.fieldName)
    }

    this.createController = () => {
      const Aeppic = this.getAeppicContext('ae-control', this.document)

      const self = this
      const locals = __NEW_LOCALS__
      const handlers = []

      const controllerVariables = {
        get $el() { return self.$el },
        get $refs() { return self.$refs },
        $listeners: self.$listeners,
        locals,
        Aeppic,
        watchParam(...args) { return self.watchParam(...args) },
        get readonly() { return self.readonly },
        get DateTime() { return Aeppic.DateTime },
        get Math() { return Aeppic.Math },
        get Anime() { return Aeppic.Anime },
        get control() { return self.control },
        get params() { return self.params },
        get document() { return self.document },
        get form() { return self.form },
        getField() { return self.getFieldValue() },
        setField(value, options) { options?.delay ? self.setFieldValueAfterDelay(value) : self.setFieldValue(value) },
        get validationErrors() { return self.getValidationErrors() },
        get validate() { return self.validate },
        fieldName: this.fieldName,
        fieldInfo: this.fieldInfo,
        $emit(...args) { return self.$emit(...args) },
        $on(...args) { 
          self.$parent.$on(...args)
          self.$on(...args)
  
          handlers.push(args)
        },
      }
      
      const controller = controllerClass ? new controllerClass(controllerVariables) : null

      return {
        Aeppic,
        controller,
        locals,
        handlers
      }
    }

    this._beforeDestroyController = () => {
      for (const handlerArgs of this.handlers) {
        this.$off(...handlerArgs) // remove all controller registered listeners (own scope)
        this.$parent.$off(...handlerArgs) // remove all controller registered listeners (parent scope)
      }
  
      this.handlers = []

      this.Aeppic.release()
      this.Aeppic = null
      this.packages = null
    }
  },
  data() {
    const { controller, locals, Aeppic, handlers } = this.createController()

    return {
      Aeppic,
      controller,
      locals,
      packages, // global provided by dynamicComponent
      handlers
    } 
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  computed: {
    Math() { return this.Aeppic.Math },
    DateTime() { return this.Aeppic.DateTime },
    Anime() { return this.Aeppic.Anime },
    isEditing() {
      return ! this.readonly
    },
    isStandalone() {
      const hasField = this.fieldName && this.form && this.document
      return ! hasField
    },
    field: {
      get() {
        // this.revision
        return this.getFieldValue()
      },
      set(value) {
        this.setFieldValue(value)
      }
    },
    validationErrors() {
      return this.getValidationErrors()
    }
  },
  created() {
    // console.log('new dynamic control')
  },
  beforeDestroy() {
    __DESTROY_CONTROLLER__

    this._beforeDestroyController()
  },
  methods: {
    __METHODS__,
    translate(...args) { 
      return this.Aeppic.translate(...args) 
    },
    watchParam(name, cb, options) {
      if (!name) {
        return
      }

      let lastValue = undefined

      const cbWithPreCheck = (newValue, oldValue) => {
        if (Array.isArray(newValue) || lastValue !== newValue) {
          cb(newValue, oldValue)
        }

        lastValue = newValue
      }
      
      return this.$watch('params.' + name, cbWithPreCheck, options)
    }
  },
  watch: {
    document() {
      this._beforeDestroyController()

      const { controller, locals, Aeppic } = this.createController()

      this.Aeppic = Aeppic
      this.controller = controller
      this.locals = locals

      this.Aeppic.setContextRootElement(this.$el)
    },
  },
  components
}
`
